import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = props => (
  <Layout>
    <p class="mobilepadding">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 120,
        }}
      >
        <SEO title="Kathleen Hsu · About" />
        {/*         <ImgWrapper>
          <Img
            loading="eager"
            fluid={props.data.aboutPicture.childImageSharp.fluid}
          />
        </ImgWrapper> */}
        <h2>
          My name is Kathleen and I'm a product designer based in the Bay Area.
          With my background in cognitive science, I value understanding people
          to design delightful and intuitive products. I have over five years of
          experience designing at early and growth stage startups.
        </h2>
        <br></br>
        <p>
          I'm currently designing at{" "}
          <a href="https://withpower.com" target="_blank" rel="noreferrer">
            Power
          </a>
          , where I craft experiences to connect clinical researchers with
          patients looking for promising medical treatments. Previously, I led
          design on payroll compliance products at{" "}
          <a href="https://middesk.com" target="_blank" rel="noreferrer">
            Middesk
          </a>
          . Before that, I was the first designer at{" "}
          <a href="https://smartcar.com" target="_blank" rel="noreferrer">
            Smartcar
          </a>
          , an API platform for connected cars.
        </p>
        <p>
          I love being involved in the entire product development process — from
          writing product requirements, creating wireframes, testing designs
          with customers, to refining small details before shipping. I’m
          interested in working on projects that help to build a better world,
          particularly in complex and innovative industries.
        </p>
        <p>
          When I’m not designing, I enjoy going on hikes, trying new recipes,
          and doing punch needle embroidery.
        </p>
        <p>
          If you'd like to get in touch, feel free to send me an{" "}
          <a
            href="mailto:kathleenhsu2@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            email
          </a>
          . Thanks for stopping by!
        </p>
      </div>
    </p>
  </Layout>
)

export default About

/* export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    aboutPicture: file(relativePath: { eq: "about_picture.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          originalImg
          originalName
        }
      }
    }
  }
`

const ImgWrapper = styled.div`
  margin-top: 48px !important;
  margin-bottom: 48px !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  @media (max-width: 1280px) {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }
  @media (max-width: 976px) {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }
`
 */
